import './App.css';
import Mainroute from './routes/Mainroute';

function App() {
 
  return (
   <div>
    <Mainroute/>
   </div>
  );
}

export default App;
