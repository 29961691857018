import React,{useState} from 'react'
import LOGO from '../assets/images/logo.png'
import LOGO_ICON from '../assets/images/login icon.png'
import { useNavigate } from 'react-router-dom'

function Signup() {
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [name,setName] = useState('')

const handleSignup = () => {
   
  console.log(email,"email")
  console.log(password,"password")
  console.log(name,"name")
}
    const navigate = useNavigate();

    const handleNavigateToLogin = () => {
        navigate('/Login')
    }

    const handleNavigateToSignup = () => {
        navigate('/Signup')
    }
  return (
    <div className='bg-img min-h-screen bg-cover bg-center md:pb-6 pb-[9vh]'>
    <div className='flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-9' style={{background:'#1b1919'}}>
        <div className='flex justify-center md:justify-start'>
            <img src={LOGO} className='h-16 md:h-36'/>
        </div>
        <div className='flex flex-row md:gap-24 gap-4 lg:pr-40 md:pr-26 md:text-xl text-sm'>
            <p className='Poppins text-white cursor-pointer' onClick={handleNavigateToLogin}>Login</p>
            <p className='Poppins text-white cursor-pointer' onClick={handleNavigateToSignup}>Signup</p>
        </div>
    </div>

    <div class='flex items-center  justify-center xl:mt-5 mt-[8vh]'>
    <div class=' bg-white  xl:w-[28%] lg:w-[40%] md:w-2/3 md:mx-0 mx-4 py-6 rounded-2xl relative'>
        <img src={LOGO_ICON} alt="Your Image" class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2  md:w-24 md:h-24 h-20 w-20      object-cover rounded-full"/>
        
        <h1 class='md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11'>Sign up and get access to the full guide right now</h1>
        <div class='flex justify-center mt-4 px-12'>
            <input type="text" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='First Name' value={name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="text" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Last Name' />
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="date" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='E-mail Address' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="number" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Enter Pincode' />
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="text" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Select Area' />
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="text" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Select Language' />
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="text" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Style' />
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="number" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Mobile Number' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        </div>
        <div class='flex justify-center px-12 my-8'>
            <button style={{background:"#000134",fontSize:'15px'}} class='w-full rounded-md py-2 text-white font-medium Poppins' onClick={handleSignup}>Signup</button>
        </div>
    </div>
</div>


    </div>  )
}

export default Signup