import React, { useState } from 'react'
import LOGO from '../assets/images/logo.png'
import LOGO_ICON from '../assets/images/login icon.png'
import { useNavigate } from 'react-router-dom'

function Login() {
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

const handleLogin = () => {
   
  console.log(email,"email")
  console.log(password,"password")
}


    const navigate = useNavigate();

    const handleNavigateToLogin = () => {
        navigate('/Login')
    }

    const handleNavigateToSignup = () => {
        navigate('/Signup')
    }
  return (
    <div className='bg-img h-screen bg-cover bg-center'>
    <div className='flex flex-row justify-between w-full md:h-24 h-16 items-center px-4 lg:px-24 md:px-18' style={{background:'#1b1919'}}>
        <div className='flex justify-center md:justify-start'>
            <img src={LOGO} className='h-16 md:h-36'/>
        </div>
        <div className='flex flex-row md:gap-24 gap-4 lg:pr-40 pr-8 md:text-xl text-sm'>
            <p className='Poppins text-white cursor-pointer' onClick={handleNavigateToLogin}>Login</p>
            <p className='Poppins text-white cursor-pointer' onClick={handleNavigateToSignup}>Signup</p>
        </div>
    </div>
    <div class='flex items-center md:h-3/4 justify-center md:mt-0 mt-28'>
    <div class=' bg-white xl:w-1/4 lg:w-[40%] md:w-2/3  md:mx-0 mx-4 py-6 rounded-2xl relative'>
        <img src={LOGO_ICON} alt="Your Image" class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-24 md:h-24 h-20 w-20 object-cover rounded-full"/>
        
        <h1 class='md:text-2xl text-1xl text-center font-bold arial px-9 tracking-tighter md:mt-16 mt-11'>Sign up and get access to the full guide right now</h1>
        <div class='flex justify-center mt-6 px-12'>
            <input type="email" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='E-mail Address' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        <div class='flex justify-center mt-4 px-12'>
            <input type="email" class='arial text-[14px] placeholder-black border border-gray-300 w-full rounded-md p-2 outline-none' placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        </div>
        <div class='flex justify-center px-12 my-8'>
            <button style={{background:"#000134",fontSize:'15px'}} class='w-full rounded-md py-2 text-white font-medium Poppins' onClick={handleLogin}>Login</button>
        </div>
    </div>
</div>
    </div>
     )
}

export default Login