import React from 'react'
import Homescreen from '../components/homescreen/Homescreen'
import { Route, Router, Routes } from 'react-router-dom'
import Login from '../authentication/Login'
import Signup from '../authentication/Signup'

function Mainroute() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Homescreen />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Signup" element={<Signup />} />
    </Routes>
    </div>
  )
}

export default Mainroute